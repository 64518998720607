export const investigaciones = {
  items_select_reporting_institution: [
    { state: "1. CESYP - ARC", abbr: "UNO" },
    { state: "2. ESTACION GUARDACOSTAS SAN ANDRES - ARC", abbr: "DOS" },
    { state: "3. ESTACION GUARDACOSTAS PROVIDENCIA - ARC", abbr: "TRES" },
    { state: "4. MAC BIG LAGOON - PNN", abbr: "CUATRO" },
    { state: "5. CORALINA", abbr: "CINCO" },
    { state: "6. POLICIA NACIONAL", abbr: "SEIS" },
    /*{ state: "7. OTRA, CUAL?", abbr: "SIETE" }*/
  ],
  items_select_entity_product_seized: [
    { state: "1. Secretaria de Agricultura y pesca", abbr: "UNO" },
    { state: "2. MAC BIG LAGOON - PNN", abbr: "DOS" },
    { state: "3. CORALINA", abbr: "TRES" },
    /*{ state: "4. OTRA, CUAL?", abbr: "CUATRO" }*/
  ],
  items_select_entity_fishing_gear: [
    { state: "1. Secretaria de Agricultura y pesca", abbr: "UNO" },
    { state: "2. Fiscalía", abbr: "DOS" },
    { state: "3. MAC BIG LAGOON - PNN", abbr: "TRES" },
    { state: "4. CORALINA", abbr: "CUATRO" },
    /*{ state: "5. OTRA, CUAL?", abbr: "CINCO" }*/
  ],
  items_select_penalty_infringement_type: [
    { state: "1. Conminación por escrito", abbr: "UNO" },
    { state: "2. Multa", abbr: "DOS" },
    {
      state:
        "3. Suspensión temporal del permiso, Autorización, concesión o patente, según sea el caso",
      abbr: "TRES",
    },
    {
      state:
        "4. Revocatoria del permiso, Autorización, concesión o patente, según sea el caso",
      abbr: "CUATRO",
    },
    {
      state: "5. Decomiso de embarcaciones, equipos o productos",
      abbr: "CINCO",
    },
    {
      state: "6. Cierre temporal o clausura definitiva del establecimiento",
      abbr: "SEIS",
    },
    { state: "7. Otro, Cual?", abbr: "SIETE" },
  ],
};
